.sidemenu .accordion {
  background: transparent;
}
.sidemenu .accordion .panel-title {
  color: #b8c7ce;
}
.sidemenu .accordion .accordion-header {
  background: #222d32;
  color: #b8c7ce;
  opacity: 0.75;
}
.sidemenu .accordion .accordion-body {
  background: #2c3b41;
  color: #8aa4af;
  opacity: 0.75;
}
.sidemenu .accordion .accordion-header-selected {
  background: #1e282c;
}
.sidemenu .tree-node-hover {
  background: #2c3b41;
  color: #fff;
}
.sidemenu .tree-node-selected {
  background: #00bbee;
  color: #fff;
}
.sidemenu .accordion-header .panel-tool {
  display: none;
}
.sidemenu .accordion-header::after,
.sidemenu .tree-node-nonleaf::after {
  display: inline-block;
  vertical-align: top;
  border-style: solid;
  transform: rotate(45deg);
  width: 4px;
  height: 4px;
  content: "";
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -3px;
  border-width: 0 1px 1px 0;
}
.sidemenu .accordion-header-selected::after {
  transform: rotate(-135deg);
}
.sidemenu .tree-node-nonleaf::after {
  transform: rotate(-135deg);
}
.sidemenu .tree-node-nonleaf-collapsed::after {
  transform: rotate(45deg);
}
.sidemenu-collapsed .accordion-header::after {
  display: none;
}
.sidemenu-tooltip .accordion {
  border-color: #1e282c;
}

html,
body {
  margin: 0;
  padding: 0;
}
.app-logo {
  width: 24px;
  height: 24px;
  color: #fff;
  margin: 13px 10px;
}
.main-bar {
  background: #1E282D url('css/images/bg_hero_home.webp') no-repeat 0 0; 
}
.main-header {
  background: #1e282c;
  color: #b8c7ce;
  opacity: 0.75;
  line-height: 50px;
  height: 50px;
  padding-right:5px;
  overflow: hidden;
}
.main-title {
  font-size: 20px;
  text-align: center;
  text-shadow: 2px 2px 4px #000000;
}
.main-toggle {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin: 0 10px;
}
.main-center {
  background: transparent;
  color: #b8c7ce;
  line-height: 50px;
  height: 50px;
  overflow: hidden;
}
.main-body {
  background: #ecf0f5;
  min-height: 400px;
}
.sidebar-body {
  background: url('css/images/bg_hero_home.webp') no-repeat 0 0;
}
.sidebar-user {
  color: #fff;
  padding: 20px;
  line-height: 20px;
}