@import '~rc-easyui/dist/themes/material/easyui.css';
/*@import 'css/easyui.css';*/
@import '~rc-easyui/dist/themes/icon.css';
@import '~rc-easyui/dist/themes/react.css';
@import '~@fortawesome/fontawesome-free/css/all.css';
@import 'SideMenu.css';

@import '~react-toastify/dist/ReactToastify.min.css';
@import '~react-chatbox-component/dist/style.css';

.fadeout {
  animation-duration: 5s;
  animation-fill-mode: both;
  animation-name: fadeOut;
  animation-timing-function: "ease-in"
}
@keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0.05;}
}

.shadowpulse {
  animation: shadowPulse 1.5s infinite;
}
@keyframes shadowPulse {
  0% {
    box-shadow: 0px 0px 3px 2px rgba(0, 178, 227, 0.3) /* #00B2E3 */
  }
  50% {
    box-shadow: 0px 0px 3px 2px rgba(0, 178, 227, 1) /* #00B2E3 */
  }
  100% {
    box-shadow: 0px 0px 3px 2px rgba(0, 178, 227, 0.3) /* #00B2E3 */
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 90%; /* overrides browser defaults */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.textbox-invalid {
  border-color: #ffa8a8;
  background-color: #fff3f3;
}
.validatebox-invalid {
  border-color: #ffa8a8;
  background-color: #fff3f3;
  color: #404040;
}
.noticebox {
  border-color: #fa8181;
  background-color: #f8c9c9;
  color: #ff0000;
  font-size: 18px;
  font-weight: bold;
  padding: 2px;
}
.form-field-focused {
  box-shadow: 0px 0px 3px 2px #eae885;
  background-color: #eae885;
}
.color-black {
  color: #000000;
}
.color-orangered {
  color: orangered;
}
.instruction {
  font-size: 14px;
  border-width: 1px;
  border-style: solid;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-radius: 4px 4px 4px 4px;
  border-radius: 4px 4px 4px 4px;
  background-color: #eae885;
  line-height: 21px;
  padding: 0px 3px;
}

.groupbox {
  display: inline-block;
  border-width: 1px;
  border-style: solid;
  border-color: #00B2E3;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-radius: 4px 4px 4px 4px;
  border-radius: 4px 4px 4px 4px;
  padding: 5px 0px 0px 3px;
  vertical-align: bottom
}

/*easyui overrides */
.datagrid-header-row, .datagrid-row {
  height: 26px;
}
.datagrid-header .datagrid-cell span {
  font-size: 12px; /* 14px */
  white-space: normal;
}
.datagrid-filter-c {
  height: 30px;
}
.datagrid-filter-c> .f-field, .datagrid-filter-c> * {
  height: 24px;
}

.l-btn-icon {
  font-size: inherit;
}
.l-btn-text {
  font-size: 12px; /* 14px */
}

.main-header {
  height: 44px;
  line-height: 44px;
}

.menu-text {
  height: 28px;
  line-height: 28px;
}
.menu-text, .menu-text span{
  font-size: .8em; /* 14px */
}

.panel-body {
  font-size: 12px; /* 14px */
}
.panel-title {
  font-size: 12px; /* 14px */
}

.sidemenu .tree-title {
  margin: 6px 0;
}

.sidemenu .tree-node {
  height: 35px;
}

.textbox .textbox-text {
  font-size: 12px; /* 14px */
}

.tree-title {
  font-size: 12px; /* 14px */
}
/*end easyui overrides */

/*chat overrides
box and page heights are based on the ChatWindow dialog height
*/
.avatar {
  width: 20px;
  height: 20px;
}
.chat-box {
  height: 270px;
}
.chat-bubble {
  font-size: 12px;
  padding: 5px;
}
.chat-bubble-row {
  padding: 3px 8px;
}
.msg-footer {
  height: 30px;
}
#end-of-chat {
  padding-bottom: 0px;
}
.message-input {
  padding: 2px 8px;
  font-size: 12px;
}
.msg-page {
  height: 245px;
}
.sender-name {
  font-size: 12px;
}
/*end chat overrides */

/* fix tooltip conflict between easyui and chatbox  */
.tooltip {
  opacity: 1;
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /*width: 100%;*/
}

.flex-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}
.flex-column2 {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
}
.flex-column3 {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 3;
}

/* do print last for overrides? */
@media print {
  .noprint {
    display: none;
  }
}
